import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
    <span>NOSTUM TECHNOLOGIES SAPI DE CV</span>
    </div>
  )
}

export default Footer