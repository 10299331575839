import React from 'react'
import logo from '../../assets/svg/nostum_logo.svg'
import './Header.scss'
const Header = () => {

  const handleHomeScreen = () => {
    window.location.href = '/'

  }

  return (
   <div className="header" >
   <img src={logo} alt="Nostum logo" onClick={()=>{handleHomeScreen()}} />
   </div>
  )
}

export default Header