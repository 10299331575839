import React from "react";
import "./Homepage.scss";
import { ReactComponent as Arrow } from "../../assets/svg/Icon material-arrow-forward.svg";
import Node from "../../assets/Node-min.jpg";
import Next from "../../assets/Next-min.jpg";
import ReactIcon from "../../assets/React-min.jpg";
import Python from "../../assets/Python-min.jpg";

import Laravel from "../../assets/Laravel-min.jpg";
import Tailwind from "../../assets/Tailwindcss-min.jpg";
import Graphql from "../../assets/GraphApolo-min.jpg";
import Webpack from "../../assets/Webpack-min.jpg";

import Appsync from "../../assets/AppSync-min.jpg";
import Cognito from "../../assets/Cognito-min.jpg";
import Lambda from "../../assets/Lambda-min.jpg";
import EC2 from "../../assets/EC2-min.jpg";
import vpc from "../../assets/VPC-min.jpg";
import useOnScreen from "../../custom-hooks/useOnScreen";
import lozad from "lozad";

const Homepage = () => {
  const titleRef = React.useRef(null);
  const bannerRef = React.useRef(null);
  const stackRef = React.useRef(null);
  const contactRef = React.useRef(null);

  const onScreentitleRef = useOnScreen(titleRef);
  const onScreenBannerRef = useOnScreen(bannerRef);
  const onScreenStackRef = useOnScreen(stackRef);
  const onScreenContactRef = useOnScreen(contactRef);
  //scroll into contact
  const scrollToContact = () => {
    document.getElementById("contact").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  React.useEffect(() => {
    const observer = lozad(".lozad", {
      rootMargin: "300px 0px",
    });
    observer.observe();
  }, []);
  const handleLoad = (el) => {
    console.log(el.target.id);
    document.querySelector(`#${el.target.id}`)?.classList.add("fade-lazy");
  };

  return (
    <div className="homepage">
      <h1
        ref={titleRef}
        className={`title fade-in-section slide-left  ${
          onScreentitleRef ? "is-visible" : ""
        }`}
      >
        Building a future of possibilities
      </h1>
      <button
        className={`homepage_contact fade-in-section slide-left  ${
          onScreentitleRef ? "is-visible" : ""
        }`}
        onClick={() => scrollToContact()}
      >
        <Arrow className="arrow" />
        <span className="hover-underline-animation">Get in Touch</span>
      </button>
      <div ref={bannerRef} className={`homepage_banner`}>
        <p
          className={`homepage_banner fade-in-section slide-top ${
            onScreenBannerRef ? "is-visible" : ""
          }`}
        >
          We deliver high-end software services for web and mobile development.
        </p>
      </div>

      <div className="homepage_stack">
        <h2
          ref={stackRef}
          className={`fade-in-section slide-left  ${
            onScreenStackRef ? "is-visible" : ""
          }`}
        >
          Meet Our Technology Stack
        </h2>
        <div className={`homepage_stack_container`}>
          
            <img
              data-src={Node}
              alt="Node js"
              id={"node"}
              className="lozad"
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />

            <img
            data-src={Next}
              alt="Next js"
              className="lozad"
              id={"next"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={ReactIcon}
              alt="ReactJS"
              className="lozad"
              id={"react"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={Python}
              alt="Python"
              id={"python"}
              className="lozad"
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
        
            <img
            data-src={Laravel}
              alt="Laravel"
              className="lozad"
              id={"laravel"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={Tailwind}
              alt="Tailwind"
              className="lozad"
              id={"tailwind"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={Graphql}
              alt="Graphql"
              className="lozad"
              id={"graphql"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={Webpack}
              alt="Webpack"
              className="lozad"
              id={"webpack"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
          

         
            <img
            data-src={Appsync}
              alt="Appsync"
              className="lozad"
              id={"appsync"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={Cognito}
              alt="Cognito"
              className="lozad"
              id={"cognito"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={Lambda}
              alt="Lambda"
              className="lozad"
              id={"lambda"}
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            <img
            data-src={EC2}
              alt="EC2"
              id={"ec2"}
              className="lozad"
              onLoad={(event) => {
                handleLoad(event);
              }}
              onError={handleLoad}
            />
            
       
        </div>
      </div>

      <div
        ref={contactRef}
        className="homepage_banner homepage_contact_container"
      >
        <h3
          className={`fade-in-section slide-bottom  ${
            onScreenContactRef ? "is-visible" : ""
          } `}
        >
          Let’s work together!
        </h3>
        <a
          href="mailto:info@nostum.com"
          id="contact"
          className={`fade-in-section slide-top  ${
            onScreenContactRef ? "is-visible" : ""
          } `}
        >
          info@nostum.com
        </a>
      </div>
    </div>
  );
};

export default Homepage;
