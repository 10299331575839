
import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Homepage from './pages/homepage/Homepage';

function App() {
  return (
    <div className="nostum_container">
    <Header/>
    <Homepage/>
    <Footer/>
    </div>
  );
}

export default App;
